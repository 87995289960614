// eslint-disable-next-line max-classes-per-file
import { LitElement, TemplateResult, css, unsafeCSS } from 'lit-element';
import { customElement, property, state } from 'lit-element/lib/decorators';
import { CSSResultGroup } from '../../ts/types';
import { Criteria } from '../../utils/mobius/ts/degrees.d';
import { LayoutType, Options } from './interfaces.d';
import categoryMap from '../../utils/mobius/maps/category.json';
import degreeMap from '../../utils/mobius/maps/degree.json';
import getAliasParams from '../../utils/tagular/getAliasParams';
import getPresets from '../../utils/getPresets';
import getTrafficSource from '../../utils/getTrafficSource';
import getUrlQuery from '../../utils/mobius/lib/getUrlQuery';
import mobiusDegrees, { Degrees } from '../../utils/mobius/degrees';
import setCustomAttribute from '../../utils/newRelic/setCustomAttribute';
import styles from './styles.scss';
import subjectMap from '../../utils/mobius/maps/subject.json';
import tagularEvent from '../../utils/tagular/event';
import template from './template';
import uuid from '../../utils/uuid';
import setMarkMetric from '../../utils/performance/setMarkMetric';
import setResourceMetric from '../../utils/performance/setResourceMetric';
import voyagerData from './data/voyager.json';
import voyagerDataVip from './data/voyagerVipMonarch.json';
import publishers from './data/publishers.json';
import { useMonarchRule } from '../../utils/monarch';

const useMonarchRulePromise = useMonarchRule('vipExperience');

class WebComponent extends LitElement {
  /**
   * mobius
   *
   * @param {Degrees} mobius mobius api response of degrees.
   */
  mobius: Degrees;

  /**
   * token
   *
   * @param {string} token the mobius token
   */
  @property({ type: String })
  token: string;

  /**
   * viewCorrelationid
   *
   * @param {string} uuid
   */
  @property({ type: String })
  viewCorrelationId;

  /**
   * formatSubtype
   *
   * @param {string} formatSubtype
   */
  @property({ type: String })
  formatSubtype = 'sonic-qdf';

  /**
   * formatType
   *
   * @param {string} formatType
   */
  @property({ type: String })
  formatType = 'widget';

  /**
   * brand
   *
   * @param {string} brand
   */
  @property({ type: String })
  brand: string;

  /**
   * image
   *
   * @param {string} image
   */
  @property({ type: String })
  image: string;

  /**
   * mobileImage
   *
   * @param {string} mobileImage
   */
  @property({ type: String })
  mobileImage: string;

  /**
   * degree
   *
   * @param {string} degree selected degree
   */
  @property({ type: String, reflect: true })
  degree: string;

  /**
   * Default Degree
   *
   * @param {string} _degree degree default
   */
  @property({ type: String })
  _degree: string;

  /**
   * category
   *
   * @param {string} category selected category
   */
  @property({ type: String, reflect: true })
  category: string;

  /**
   * Default Category
   *
   * @param {string} _category category default
   */
  @property({ type: String })
  _category: string;

  /**
   * subject
   *
   * @param {string} subject selected subject.
   */
  @property({ type: String, reflect: true })
  subject: string;

  /**
   * Default Subject
   *
   * @param {string} _subject subject default
   */
  @property({ type: String })
  _subject: string;

  /**
   * layout
   *
   * @param {LayoutType} layout different layout types.
   */
  @property({ type: String, reflect: true })
  layout: LayoutType = 'vertical';

  /**
   * subLayout
   *
   * @param {string} subLayout used in QDF select.
   */
  @property({ type: String, reflect: true })
  subLayout: string;

  /**
   * tooltipPosition
   *
   * @param {string} tooltipPosition used to specify the tooltip position
   */
  @property({ type: String, reflect: true })
  tooltipPosition: string;

  /**
   * target
   *
   * Target allows to direct the button for the qdf.
   * '_blank' opens the link a new tab
   * '_self' opens the link on the same tab
   * @param {string} target different window targets.
   */
  @property({ type: String })
  target = '_blank';

  /**
   * formName
   *
   * Human readable name from the HTML form name.
   * Not standardized, if you want to make this
   * business specific, location specific, go for it.
   *
   * @param {string} formName form name
   */
  @property({ type: String })
  formName = 'widget';

  /**
   * formType
   *
   * Human-assigned name that specifies what kind of
   * form it is. Type is highly standardized in order
   * to have a single definition for specific types of
   * forms. We would like this to be filled out
   * on every event
   *
   * @param {string} formType form type
   */
  @property({ type: String })
  formType = `sonic-qdf:${this.layout}`;

  /**
   * formVersion
   *
   * Human readable name from the HTML form version.
   * Not standardized, if you want to make this
   * business specific, location specific, go for it.
   *
   * @param {string} formName form name
   */
  @property({ type: String })
  formVersion;

  /**
   * disclosure
   *
   * @param {boolean} disclosure toggle ad disclosure.
   */
  @property({ type: Boolean })
  hideDisclosure;

  /**
   * form
   *
   * @param {boolean} form toggle form.
   */
  @property({ type: Boolean })
  hideForm;

  /**
   * stacked
   *
   * @param {boolean} stacked qdf form on top of button.
   */
  @property({ type: Boolean })
  stacked;

  /**
   * preventElementEvents
   *
   * @param {boolean} preventElementEvents toggle the firing of element events.
   * helps in preamp tests
   */
  @property({ type: Boolean })
  preventElementEvents;

  /**
   * preventProductEvents
   *
   * @param {boolean} preventProductEvents toggle the firing of Product events.
   * helps in preamp tests
   */
  @property({ type: Boolean })
  preventProductEvents;

  /**
   * preventFormEvents
   *
   * @param {boolean} preventFormEvents toggle the firing of Form events.
   * helps in preamp tests
   */
  @property({ type: Boolean })
  preventFormEvents;

  /**
   * elementPayload
   *
   * @param {object} elementPayload stringified option object for webElement part of element event.
   * helps in preamp tests
   * const elementPayload = {
        elementType,
        htmlId,
        location,
        name,
        position
        text,
      }
   */
  @property({ type: Object })
  elementPayload;

  /**
   * autoSubmit
   *
   * @param {boolean} autoSubmit toggle auto submit.
   */
  @property({ type: Boolean })
  autoSubmit;

  /**
   * button
   *
   * @param {string} button submission button label.
   */
  @property({ type: String, reflect: true })
  button = 'Search Programs';

  /**
   * buttonAlign
   *
   * @param {string} buttonAlign alignment of button
   */
  @property({ type: String, reflect: true })
  buttonAlign = 'center';

  /**
   * mobileButtonPosition
   *
   * @param {string} mobileButtonPosition show cta above or widget select options
   */
  @property({ type: String })
  mobileButtonPosition: string;

  /**
   * border
   *
   * @param {boolean} border toggle shadow border
   */
  @property({ type: Boolean, reflect: true })
  border = false;

  /**
   * setAliasParams
   *
   * @param {boolean} setAliasParams toggle tagular alias query params
   */
  @property({ type: Boolean })
  setAliasParams = true;

  /**
   * icon
   *
   * @param {string} icon add icon to button text
   */
  @property({ type: String })
  icon: string;

  /**
   * iconReverse
   *
   * @param {boolean} iconReverse toggles whether icon is before or after button text
   */
  @property({ type: Boolean })
  iconReverse = false;

  /**
   * exclude
   *
   * @param {string} exclude degree, category, subject to hide.
   */
  @property({ type: String, reflect: true })
  exclude: string;

  /**
   * options
   *
   * @param {Options} options options available for selects.
   */
  options: Options = {
    degree: [],
    category: [],
    subject: [],
  };

  /**
   * selectArrowYellow
   *
   * @param {boolean} selectArrowYellow toggles yellow for select arrow color
   */
  @property({ type: Boolean, reflect: true })
  selectArrowYellow = false;

  /**
   * degreeLabel
   *
   * @param {string} degreeLabel label for degree select
   */
  @property({ type: String })
  degreeLabel: string;

  /**
   * categoryLabel
   *
   * @param {string} categoryLabel label for category select
   */
  @property({ type: String })
  categoryLabel: string;

  /**
   * customDimensionsArray
   *
   * @param {string} customDimensionsArray additional tracking parameters as an array
   */
  @property({ type: Array })
  customDimensionsArray = [];

  /**
   * subjectLabel
   *
   * @param {string} subjectLabel label for subject select
   */
  @property({ type: String })
  subjectLabel: string;

  /**
   * disclosurePositionRight
   *
   * @param {boolean} disclosurePositionRight toggles disclosure position relative to cta button
   */
  @property({ type: Boolean, reflect: true })
  disclosurePositionRight = false;

  /**
   * disclosureLabel
   *
   * @param {string} disclosureLabel label of the disclosure
   */
  @property({ type: String })
  disclosureLabel: string;

  /**
   * url
   *
   * @param {string} url destination url.
   */
  @property({ type: String })
  url = 'https://schools.collegedegrees.com/app/experience';

  /**
   * appUrl
   *
   * @param {string} appUrl main app destination url.
   */
  @property({ type: String })
  appUrl = '';

  /**
   * voyagerTarget
   *
   * @param {string} voyagerTarget set window target for voyager
   */
  @property({ type: String })
  voyagerTarget = '';

  /**
   * voyagerUrl
   *
   * @param {string} voyagerUrl new voyager destination url.
   */
  @property({ type: String })
  voyagerUrl = '';

  /**
   * voyagerArray
   *
   * @param {Array} voyagerArray list of DCS combos that go to voyager
   */
  @state()
  voyagerArray = voyagerData.all;

  /**
   * voyagerArray
   *
   * @param {Array} voyagerArray list of DCS combos that go to voyager
   */
  static readonly voyagerVip: Array<string> = voyagerDataVip.vip;

  @property({ type: Object })
  monarchRules;

  /**
   * voyagerExtraOptions
   *
   * @param {string} voyagerExtraOptions which degrees/categories go to voyager.
   */
  @property({ type: String })
  voyagerExtraOptions = '';

  /**
   * extclid
   *
   * @param {string} extclid when present, adds this property to the query string of the URL
   */
  @property({ type: String })
  extclid = '';

  /**
   * publisher
   *
   * @param {string} publisher the publisher slug
   */
  @property({ type: String })
  publisher: string;

  /**
   * source
   *
   * @param {string} source get source of traffic.
   */
  @property({ type: String })
  source: string = getTrafficSource(window.location.href);

  /**
   * observer
   *
   * @param {IntersectionObserver} observer intersection observer api
   */
  @property({ type: Object })
  observer: IntersectionObserver = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        // tagular event: ElementViewed
        if (!this.preventElementEvents && this.layout !== 'cta') {
          this.tagularElementViewed();
        }
        // tagular event: ProductViewed
        if (!this.preventProductEvents) {
          this.tagularProductViewed();
        }
        if (!this.preventFormEvents) {
          // tagular event: FormViewed
          this.tagularFormViewed();
        }

        // disconnect observer
        this.observer.disconnect();
      }
    },
    {
      threshold: 0.5,
    }
  );

  /*
   * styles
   *
   * lit-element property declaration
   */
  static get styles(): CSSResultGroup {
    return [css`${unsafeCSS(styles)}`];
  }

  /*
   * render
   *
   * lit-element lifecycle method
   */
  render(): TemplateResult {
    if (!this.mobius) {
      return null;
    }

    // metric: html-rendered mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'html-rendered',
      newRelic: true,
      measures: [
        {
          startMark: 'api-response',
        },
        {
          startMark: 'dom-connected',
          newRelic: true,
        },
      ],
    });

    return template(this);
  }

  /*
   * connectedCallback
   *
   * fires each time a custom element is appended
   * into a document-connected element.
   */
  async connectedCallback(): Promise<void> {
    this.viewCorrelationId = uuid();

    // metric: component resource
    setResourceMetric({
      namespace: this.tagName,
      resource: 'sonic-qdf.js',
      measures: [
        {
          label: 'startTime',
          data: 'fetchStart',
        },
        {
          label: 'fetchStart -> responseEnd',
          data: 'duration',
        },
        {
          label: 'responseEnd',
          data: 'responseEnd',
        },
      ],
    });

    // metric: dom-connected mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'dom-connected',
      newRelic: true,
    });

    super.connectedCallback();

    if (!this.publisher) {
      this.publisher = window?.HE?.publisher?.name || '';
    }

    // determine voyager options
    this.determineVoyagerOptions();

    // presets
    getPresets(this);
    // set property appUrl so we have it no matter what the url
    this.setAttribute('appUrl', this.url);

    // criteria
    const criteria: Criteria = this.getCriteria();

    // metric: api-request mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'api-request',
      measures: [
        {
          startMark: 'dom-connected',
        },
      ],
    });

    // mobius
    this.mobius = await mobiusDegrees({
      context: {
        experience: 'quick-degree-finder',
      },
      criteria,
      token: this.token,
      trackingContext: {
        formatSubtype: this.formatSubtype,
        formatType: this.formatType,
      },
    });

    // metric: api-request mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'api-response',
      measures: [
        {
          startMark: 'api-request',
          newRelic: true,
        },
      ],
    });

    // fallback, if no results
    if (!this.mobius.response || !this.mobius.response.degrees.length) {
      this.layout = 'fallback';
      setCustomAttribute('NoQdfMobiusResponse', 'true');
    }

    // new relic: results empty
    if (
      this.mobius.response &&
      Array.isArray(this.mobius.response.degrees) &&
      this.mobius.response.degrees.length === 0
    ) {
      setCustomAttribute('QdfMobiusResponseEmpty', 'true');
    }

    // Adding extclid (External Click ID - to be used by affiliates) to the customDimensions array
    if (this.extclid) {
      this.customDimensionsArray.push({
        key: 'extclid',
        value: this.extclid,
      });
    }

    // update options & manual request update
    await this.updateOptions();

    // exclusion
    this.updateExclusions();

    // update url when using voyager
    if (this.voyagerUrl) {
      // check if voyagerTarget is also set
      // and set target
      if (this.voyagerTarget !== '') {
        this.target = this.voyagerTarget;
      }

      // update url
      this.updateUrl('');
    }
    if (!this.preventProductEvents) {
      // tagular event: ProductLoaded
      this.tagularProductLoaded();
    }

    // observer element to viewport
    this.observer.observe(this);

    useMonarchRulePromise
      .then((evaluation) => {
        this.monarchRules = { vipExperience: evaluation };
        this.updateUrl('');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /*
   * attributeChangedCallback
   *
   * fires each time an attribute is updated
   */
  attributeChangedCallback(name, oldval, newval) {
    // update url when using voyager, for slow preamp loads
    if (this.voyagerUrl && name === 'voyagerextraoptions' && newval) {
      this.updateUrl(newval);
    }
    super.attributeChangedCallback(name, oldval, newval);
  }

  /**
   * determineVoyagerOptions
   *
   * utility that checks which publisher the QDF is on
   * and uses that to add combos to the voyager array
   */
  determineVoyagerOptions() {
    // attempt to grab the publisher from window
    // if publisher does not exist, QDF is on an affiliate site
    const publisherName = window?.HE?.publisher?.name;

    // if no publisher exit function
    if (!publisherName) return;

    // grab all array keys from voyager.json
    const voyagerDataKeys = Object.keys(voyagerData);

    // if publisherName has a corresponding array
    // in voyagerData, add that array to the options
    if (voyagerDataKeys.includes(publisherName)) {
      this.voyagerArray = this.voyagerArray.concat(voyagerData[publisherName]);
    }

    // if publisherName matches any of the o&o publishers
    // add ownedAndOperated array to options
    if (publishers.includes(publisherName)) {
      this.voyagerArray = this.voyagerArray.concat(
        voyagerData.ownedAndOperated
      );
    }
  }

  /**
   * getCriteria
   *
   * get local attribute context then window contextual defaults.
   */
  getCriteria(): Criteria {
    const criteria: Criteria = {};

    // defaults, local attributes > contextual defaults
    if (!this.degree && !this.category && !this.subject) {
      this.degree = this._degree;
      this.category = this._category;
      this.subject = this._subject;
    }

    if (this.degree === 'general') {
      this.degree = '';
    }
    if (this.category === 'general') {
      this.category = '';
    }
    if (this.subject === 'general') {
      this.subject = '';
    }
    // degree
    if (
      degreeMap?.[this.degree] &&
      this.exclude &&
      this.exclude.includes('degree')
    ) {
      criteria.degreeId = degreeMap[this.degree];
    }

    // category
    if (
      categoryMap?.[this.category] &&
      this.exclude &&
      this.exclude.includes('category')
    ) {
      criteria.categoryId = categoryMap[this.category];
    }
    // return
    return criteria;
  }

  /**
   * handleChange
   *
   * Handle the degree/category/subject select changes.
   *
   * @param {MouseEvent} ev mouse change event.
   */
  handleChange(ev: MouseEvent): void {
    const { value, name } = ev.currentTarget as HTMLSelectElement;

    if (name === 'subject') {
      const { slug } = this.options.category.find((cat) => {
        return cat.subject.some((subj) => subj.slug === value);
      });
      this.category = slug;
    }

    // set property degree/category/subject
    this.setAttribute(name, value);

    // remove property(s) category/subject
    if (!this.exclude) {
      switch (name) {
        case 'degree': {
          this.removeAttribute('category');
          this.removeAttribute('subject');
          break;
        }
        case 'category': {
          this.removeAttribute('subject');
          break;
        }
        default: {
          break;
        }
      }
    }

    if (this.degree === 'general') {
      this.category = 'general';
      this.subject = 'general';
    }
    // update options & manual request update
    this.updateOptions();

    // update url when using voyager
    if (this.voyagerUrl) {
      // check if voyagerTarget is also set
      // and set target
      if (this.voyagerTarget !== '') {
        this.target = this.voyagerTarget;
      }

      // update url
      this.updateUrl('');
    }

    // tagular event: FieldSelected
    // redventures.usertracking.v3.FieldSelected
    // https://app.make.rvapps.io/schemas/sch_1KdMzkBDoqiLnx5EJXZkiAkn2aY
    tagularEvent('FieldSelected', {
      formContext: {
        formType: this.formType || this.formatSubtype,
        formName: this.formName || this.formatType,
        formId: this.mobius.id,
        formVersion: this.formVersion,
      },
      userInputField: {
        fieldType: name,
        fieldName: name,
        fieldValue: value,
      },
      correlationId: this.mobius.id,
    });

    if (name === 'subject' && this.autoSubmit) {
      // correlation id generated on click
      const correlationId = uuid();

      // outbound url
      const outboundUrl = getUrlQuery(this.url, {
        publisher: this.publisher,
        url: window.location.href,
        degree: this.degree || '',
        category: this.category || '',
        subject: this.subject || '',
        extclid: this.extclid,
        correlationId,
        trafficSource: this.source,
        ...(this.setAliasParams ? getAliasParams() : {}),
      });
      if (!this.preventFormEvents) {
        // tagular event: FormSubmitted
        this.tagularFormSubmitted(correlationId);
      }

      // link
      window.open(outboundUrl, this.target, 'noopener');
    }
  }

  /**
   * handleClick
   *
   * Handle the submission click.
   */
  handleClick(): void {
    // correlation id generated on click
    const correlationId = uuid();
    // outbound url, new voyager format first
    let outboundUrl;
    if (this.url === this.voyagerUrl) {
      const degreeTextObj = this.options.degree.find((obj) => {
        return obj.slug === this.degree;
      });
      const categoryTextObj = this.options.category.find((obj) => {
        return obj.slug === this.category;
      });
      const subjectTextObj = this.options.subject.find((obj) => {
        return obj.slug === this.subject;
      });

      outboundUrl = getUrlQuery(this.url, {
        publisher: this.publisher,
        url: window.location.href,
        'dcs[degrees][]': degreeTextObj?.name || '',
        'dcs[categories][]': categoryTextObj?.name || '',
        'dcs[subjects][]': subjectTextObj?.name || '',
        extclid: this.extclid,
        elementClickID: correlationId,
        trafficSource: this.source,
        ...(this.setAliasParams ? getAliasParams() : {}),
      });
    } else {
      outboundUrl = getUrlQuery(this.url, {
        publisher: this.publisher,
        url: window.location.href,
        degree: this.degree || '',
        category: this.category || '',
        subject: this.subject || '',
        extclid: this.extclid,
        correlationId,
        trafficSource: this.source,
        ...(this.setAliasParams ? getAliasParams() : {}),
      });
    }

    // tagular event: ElementClicked
    if (!this.preventElementEvents) {
      this.tagularElementClicked(correlationId, outboundUrl);
    }

    // If User selected Undecided, save the Undecided Label and use it for eventing
    let degreeSelect;
    [...this.shadowRoot.querySelectorAll('select')].forEach(
      (select: HTMLSelectElement) => {
        select.name === 'degree'
          ? (degreeSelect = select.options[select.selectedIndex]?.innerText)
          : '';
      }
    );

    const degreeIsUndecided = Boolean(degreeSelect === 'Undecided');

    // tagular event: ProductClicked
    // redventures.ecommerce.v1.ProductClicked
    // https://app.make.rvapps.io/schemas/sch_1KdMzUut6pgFqvEatLszkBGhCob
    if (!this.preventProductEvents) {
      tagularEvent('ProductClicked', {
        correlationId,
        outboundUrl,
        product: {
          category: degreeIsUndecided
            ? 'Undecided'
            : this.category || 'general',
          formatSubtype: this.formatSubtype,
          formatType: this.formatType,
          brand: this.brand || '',
          location:
            this.formatSubtype === 'native-widget'
              ? 'rankings-list'
              : this.layout,
          position: parseInt(this.elementPayload?.position) || null,
          positionEngine: this.layout === 'native' ? 'manual' : 'null',
          name: degreeIsUndecided ? 'Undecided' : this.subject || 'general',
          variant: degreeIsUndecided ? 'Undecided' : this.degree || 'general',
          productId: subjectMap?.[this.subject]
            ? subjectMap?.[this.subject].toString()
            : null,
        },
        viewCorrelationId: this.mobius.id,
      });
    }
    if (!this.preventFormEvents) {
      // tagular event: FormSubmitted
      this.tagularFormSubmitted(correlationId);
    }

    // link
    window.open(outboundUrl, this.target, 'noopener');
  }

  /**
   * updateExclusions
   *
   * based off of set degree, category, subject and results from
   * mobius, apply exclusions correctly.
   */
  updateExclusions(): void {
    // return, no excludes set
    if (!this.exclude) {
      return;
    }

    // remove commas & spaces
    this.exclude = this.exclude.replace(/[, ]/g, '');

    // subjects - un-excludable
    this.exclude = this.exclude.replace('subject', '');

    // category - un-excludable if no category set or no categories in options
    if (!this.category || !this.options.subject.length) {
      this.exclude = this.exclude.replace('category', '');
    }

    // degree - un-excludable if no degree set or no degrees in options
    if (!this.degree || !this.options.category.length) {
      this.exclude = this.exclude.replace('degree', '');
    }
  }

  /**
   * updateOptions
   *
   * Updates the options lists that are fed to the select buttons.
   * Once property is updated triggers a manual request update to
   * re-hydrate the rendered markup.
   */
  async updateOptions(): Promise<void> {
    // update options
    this.options = await {
      degree: this.mobius.degree(),
      category: this.mobius.category(this.degree),
      subject: this.mobius.subject(this.degree, this.category),
    };

    // manually start an update
    this.requestUpdate();
  }

  /**
   * updateUrl
   *
   * Updates the url if we're using voyager
   */
  updateUrl(extraOptions: string): void {
    let currentSelections = '';
    if (this.degree === 'masters' || this.degree === 'doctorate') {
      if (this.subject) {
        currentSelections = `${this.degree} + ${this.category} + ${this.subject}`;
      } else {
        currentSelections = `${this.degree} + ${this.category || 'general'}`;
      }
    } else {
      currentSelections = `${this.degree} + ${this.category || 'general'}`;
    }

    // this sets the url to voyager if there is a match in the voyagerExtraOptions
    // property on the qdf element -- OR -- if it is turned on in the
    // voyagerArray array
    let isVoyagerOption =
      extraOptions.includes(currentSelections) ||
      this.voyagerExtraOptions.includes(currentSelections) ||
      this.voyagerArray.includes(currentSelections);

    const degreeSubject = `${this.degree} + ${this.subject}`;

    // If vip experience is false, then send to StudyMatch.
    const isVIPExperienceTest = this.monarchRules?.vipExperience?.vipExperience;

    if (
      WebComponent.voyagerVip.includes(degreeSubject) &&
      isVIPExperienceTest
    ) {
      isVoyagerOption = false;
    }

    if (isVoyagerOption) {
      this.setAttribute('url', this.voyagerUrl);
    } else {
      this.setAttribute('url', this.appUrl);
    }
  }

  /**
   * tagularProductLoaded
   *
   * tagular event: ProductLoaded
   * redventures.ecommerce.v1.ProductLoaded
   * https://app.make.rvapps.io/schemas/sch_1MyCkGNK8h7RRi2qEAhT5DFEOt3
   * trigger ProductViewed on when qdf viewed.
   */
  tagularProductLoaded(): void {
    tagularEvent('ProductLoaded', {
      product: {
        category: this.category || 'general',
        formatSubtype: this.formatSubtype,
        formatType: this.formatType,
        brand: this.brand || '',
        location:
          this.formatSubtype === 'native-widget'
            ? 'rankings-list'
            : this.layout,
        position: parseInt(this.elementPayload?.position) || null,
        name: this.subject || 'general',
        variant: this.degree || 'general',
        positionEngine: this.layout === 'native' ? 'manual' : 'null',
      },
      viewCorrelationId: this.mobius.id,
    });
  }

  /**
   * tagularProductViewed
   *
   * tagular event: ProductViewed
   * redventures.ecommerce.v1.ProductViewed
   * https://app.make.rvapps.io/schemas/sch_1KdMzjSmvkUNkkjGM5VDHhjWIzQ
   * trigger ProductViewed on when qdf loaded.
   */
  tagularProductViewed(): void {
    tagularEvent('ProductViewed', {
      product: {
        category: this.category || 'general',
        formatSubtype: this.formatSubtype,
        formatType: this.formatType,
        brand: this.brand || '',
        location:
          this.formatSubtype === 'native-widget'
            ? 'rankings-list'
            : this.layout,
        position: parseInt(this.elementPayload?.position) || null,
        name: this.subject || 'general',
        variant: this.degree || 'general',
        positionEngine: this.layout === 'native' ? 'manual' : 'null',
      },
      viewCorrelationId: this.mobius.id,
    });
  }

  /**
   * tagularFormViewed
   *
   * tagular event: FormViewed
   * redventures.usertracking.v3.FormViewed
   * https://app.make.rvapps.io/schemas/sch_1KdMzRnrZqI6vf287V6xu2ZrqdH
   * indicates when a user viewed a form
   */
  tagularFormViewed(): void {
    tagularEvent('FormViewed', {
      formContext: {
        formId: this.mobius.id,
        formName: this.formName,
        formType: this.formType,
        formVersion: this.formVersion,
      },
      correlationId: this.mobius.id,
    });
  }

  /**
   * tagularFormSubmitted
   *
   * tagular event: FormSubmitted
   * redventures.usertracking.v3.FormSubmitted
   * https://app.make.rvapps.io/schemas/sch_1KdN0AYgNg6vSZe9Z8gnMFtiB5f
   * indicates when a user submitted a form.
   */
  tagularFormSubmitted(correlationId: string): void {
    // tagular event
    tagularEvent('FormSubmitted', {
      field: [...this.shadowRoot.querySelectorAll('select')]
        .map((select: HTMLSelectElement) => ({
          fieldId: select.id,
          fieldLabel: select?.[0]?.innerText || '',
          fieldName: select.name,
          fieldType: select.type,
          fieldValue: select.value || 'general',
        }))
        .filter((select) => select.fieldName),
      formContext: {
        formId: this.mobius.id,
        formName: this.formName,
        formType: this.formType,
        formVersion: this.formVersion,
      },
      correlationId,
    });
  }

  /**
   * tagularElementClicked
   *
   * tagular event: ElementClicked
   * redventures.usertracking.v3.ElementClicked
   * https://app.make.rvapps.io/schemas/sch_1KdMzeZCVB3cFlvsaIXpcvuhBno
   * trigger ElementClicked on submit.
   */
  tagularElementClicked(correlationId, outboundUrl): void {
    if (this.url === this.voyagerUrl) {
      tagularEvent('ElementClicked', {
        actionOutcome: 'INTERNALLINK',
        correlationId: correlationId,
        viewCorrelationId: this.viewCorrelationId,
        outboundUrl: outboundUrl,
        webElement: {
          elementType: 'widget',
          location: 'hero',
          name: 'voyager_entry',
          position: 'null',
          text: this.button,
          htmlId:
            this.layout === 'native'
              ? 'voyager-native-dcs'
              : this.layout === 'cta'
              ? 'voyager_ranking_cta'
              : 'voyager_dcs',
          ...this.elementPayload,
        },
        customDimensions: this.customDimensionsArray,
      });
    } else {
      tagularEvent('ElementClicked', {
        actionOutcome: 'INTERNALLINK',
        correlationId: correlationId,
        viewCorrelationId: this.viewCorrelationId,
        outboundUrl: outboundUrl,
        webElement: {
          elementType: 'widget',
          location: this.layout,
          name: 'studymatch_entry',
          text: this.button,
          ...this.elementPayload,
        },
        customDimensions: this.customDimensionsArray,
      });
    }
  }

  /**
   * tagularElementViewed
   *
   * tagular event: ElementViewed
   * redventures.usertracking.v3.ElementViewed
   * https://app.make.rvapps.io/schemas/sch_1KdMzy1S6c7Aqjibny2JP9HC1Fb
   * trigger ElementViewed when qdf is in view.
   */
  tagularElementViewed(): void {
    if (this.voyagerUrl) {
      tagularEvent('ElementViewed', {
        viewCorrelationId: this.viewCorrelationId,
        webElement: {
          elementType: 'widget',
          htmlId:
            this.layout === 'native' ? 'voyager-native-dcs' : 'voyager_dcs',
          location: 'hero',
          name: 'voyager_entry',
          position: 'null',
          text: this.button,
          ...this.elementPayload,
        },
        customDimensions: [
          { key: 'degree', value: this.degree },
          { key: 'category', value: this.category },
          { key: 'subject', value: this.subject },
        ],
      });
    } else {
      tagularEvent('ElementViewed', {
        viewCorrelationId: this.viewCorrelationId,
        webElement: {
          elementType: 'widget',
          location: this.layout,
          name: 'studymatch_entry',
          text: this.button,
          ...this.elementPayload,
        },
        customDimensions: [
          { key: 'degree', value: this.degree },
          { key: 'category', value: this.category },
          { key: 'subject', value: this.subject },
        ],
      });
    }
  }
}

// Publisher QDF
@customElement('sonic-qdf')
export class PublisherQDF extends WebComponent {}

// Affiliate QDF
@customElement('he-qdf')
export class AffiliateQDF extends WebComponent {}
